import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Button, Input,Typography, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Close from "../../assets/images/close-x.svg";


const { TabPane } = Tabs;
const { Text } = Typography;
const JSONEditorWithModal = ({ value, onChange, isReadOnly, isModalVisible, setIsModalVisible, fieldName, form }) => {
    console.log(value, onChange, isReadOnly, isModalVisible, setIsModalVisible, fieldName )
  const [activeTab, setActiveTab] = useState('2');
  const [jsonValue, setJsonValue] = useState('');
  const [parsedValue, setParsedValue] = useState({});

  useEffect(() => {
    try {
      const parsed = typeof value === 'string' ? JSON.parse(value) : value;
      setParsedValue(parsed);
      setJsonValue(JSON.stringify(parsed, null, 2));
    } catch (error) {
      console.error('Invalid JSON:', error);
    }
  }, [value]);

  const handleOk = () => {
    if (activeTab === '2') {
      try {
        const parsed = JSON.parse(jsonValue);
        onChange(parsed);
        setParsedValue(parsed);
        form
        .validateFields()
        .then(() => {
          form.submit();
        })
        .catch((error) => {
        //   setCollapseAllGroups(true);
          console.error(JSON.stringify(error, null, 2));
        });
      } catch (error) {
        console.error('Invalid JSON:', error);
      }
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Field',
      dataIndex: 'fieldName',
      key: 'fieldName',
      width: 150,
      ellipsis: true,
      render: (text, record) => (
        <Text style={{
          paddingLeft: `${record.level * 20}px`,
          fontWeight: record.isParent ? 500 : 'normal',
          fontStyle: record.isParent ?"italic":"normal",
          color: record.isParent ? 'green' : 'rgba(0, 0, 0, 0.85)'
        }}>
          {text}
        </Text>
      )
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: 100,
      ellipsie: true,
      render: (text, record) => (
        <Text style={{
          fontStyle: record.isParent ?"italic":"normal",
          color: record.isParent ? 'green' : 'rgba(0, 0, 0, 0.85)',
          fontWeight: record.isParent ? 500 : 'normal'
        }}>
          {text}
        </Text>
      )
    }
  ];
  // const columns = [
  //   {
  //     title: 'Key',
  //     dataIndex: 'key',
  //     key: 'key',
  //     width: '40%',
  //   },
  //   {
  //     title: 'Value',
  //     dataIndex: 'value',
  //     key: 'value',
  //     width: '60%',
  //     render: (text) => <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{text}</div>,
  //   },
  // ];

  const parseJSON = (obj, parentKey = '', level = 0) => {
    if (!obj || typeof obj !== 'object') return [];

    return Object.entries(obj).flatMap(([key, value]) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      
      if (Array.isArray(value)) {
        const arrayRows = [
          {
            key: currentKey,
            fieldName: key,
            value: `Array (${value.length} items)`,
            level,
            isParent: true
          }
        ];

        value.forEach((item, index) => {
          if (typeof item === 'object' && item !== null) {
            arrayRows.push(...parseJSON(item, `${currentKey}[${index}]`, level + 1));
          } else {
            arrayRows.push({
              key: `${currentKey}[${index}]`,
              fieldName: `[${index}]`,
              value: JSON.stringify(item),
              level: level + 1
            });
          }
        });

        return arrayRows;
      }
      
      if (typeof value === 'object' && value !== null) {
        return [
          {
            key: currentKey,
            fieldName: key,
            value: 'Object',
            level,
            isParent: true
          },
          ...parseJSON(value, currentKey, level + 1)
        ];
      }

      return [{
        key: currentKey,
        fieldName: key,
        value: JSON.stringify(value),
        level
      }];
    });
  };

  const tableData = parseJSON(parsedValue);

  return (
    <Modal
    title={<>
      <center style={{fontWeight:'bold',width:'40%px',fontStyle:'normal',fontSize:"24px",textAlign:"start"}}>Edit {fieldName}</center></>}
       style={{top:"-17px"}}
      // closable={false}
      className="tabModal"
      centered
    visible={isModalVisible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={800}
    maskClosable={false}
    closeIcon={<img src={Close} alt="" style={{height:"4vh"}}/>}
    bodyStyle={{paddingTop:0}}
    okText={
      <span style={{ color: "white", width: "88px", height: "36px",fontWeight:600,borderRadius:'6px'}}>Submit</span>
    }
    cancelText={
      <span style={{  width: "88px", height: "36px",fontWeight:600,borderRadius:'6px'}}>Cancel</span>
    }
    footer={activeTab === "2" ? undefined : null} 
    >
      <Tabs activeKey={activeTab} onChange={key => setActiveTab(key)}>
        <TabPane tab="Table View" key="1">
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            size="small"
            rowKey="key"
            scroll={{ y: "60vh", x: "100%" }}
          />
        </TabPane>
        <TabPane tab="JSON Edit" key="2">
          <Input.TextArea
            value={jsonValue}
            onChange={(e) => setJsonValue(e.target.value)}
            autoSize={{ minRows: 10, maxRows: 20 }}
            disabled={isReadOnly}
            style={{ fontFamily: 'monospace' }}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default JSONEditorWithModal;

